import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap";
import BeInspiredSlider from "../components/BeInspiredSlider/BeInspiredSlider";
import "../scss/global.scss"

const TestPage = () => {
    return (
        <>
            <BeInspiredSlider />
        </>
    )
}

export default TestPage